* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-width: 330px;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #2091f9;
}

/* Header styles */
.hero {
  padding: 0px 20px;
  margin-bottom: 75px;
  width: 100%;
  background: linear-gradient(39.97deg, #00f3b9 0.79%, #2187ff 79.29%);
  /* Menu styles */
}
.hero.main {
  background: url("../images/template/main_page.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0px;
  padding: 0px;
  height: 1380px;
}
.hero .header {
  max-width: 1024px;
  margin: auto;
  padding: 50px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hero .header .logo img {
  width: 120px;
  height: 113px;
}
.hero .main-image {
  padding-bottom: 40px;
}
.hero .main-image img {
  display: block;
  width: 776px;
  margin: 0 auto;
}
.hero .menu {
  display: flex;
  align-items: center;
}
.hero .menu ul {
  list-style: none;
  display: flex;
}
.hero .menu li {
  margin-left: 30px;
}
.hero .menu a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-decoration: none;
}
.hero .menu a.active {
  color: #8ec2f2;
}
.hero .title {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
}
.hero .title h1 {
  color: #ffffff;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 58px;
  line-height: 70px;
  text-align: center;
  letter-spacing: 0.2px;
}
.hero .title-page {
  max-width: 768px;
  margin: auto;
  padding: 0 50px;
}
.hero .title-page h1 {
  padding-bottom: 60px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.hero .subtitle {
  margin-bottom: 75px;
  margin-left: 30px;
  margin-right: 30px;
}
.hero .subtitle h2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.2px;
}

.container {
  max-width: 768px;
  margin: 0 auto;
  font-size: 24px;
}
.container.main {
  margin: -250px auto 0;
}
.container .title-content {
  margin-bottom: 70px;
}
.container .title-content h3 {
  padding: 0 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #e77c40;
}
.container .block {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.container .block img {
  display: block;
  max-width: 400px;
  max-height: 400px;
}
.container .content {
  display: flex;
  flex-direction: column;
}
.container .content h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.2px;
  color: #2091f9;
  margin: 50px 30px 30px 30px;
  text-transform: uppercase;
}
.container .content h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #2091f9;
  margin: 0 30px 30px 30px;
}
.container .content h4 {
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2091f9;
  margin: 0 30px 30px 30px;
}
.container .content h4 img {
  margin-right: 5px;
  display: inline-block;
}
.container .content p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin: 0 30px 30px 30px;
}
.container .content ul {
  display: block;
  margin-left: 50px;
}
.container .content ul li {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
}
.container .content ul li a {
  color: #2091f9;
}
.container .content ul li::marker {
  color: #00f3b9;
  font-size: 14px;
}
.container .content .apk {
  display: block;
  margin: 0 30px 400px 30px;
}
.container .map {
  margin: 0 30px 80px;
}
.container .map img {
  max-width: 768px;
  width: 100%;
  display: block;
}
.container .content-address {
  margin: 0 20px 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.container .content-address .col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.container .content-address .col img {
  margin-bottom: 30px;
  display: block;
  width: 65px;
  height: 65px;
}
.container .content-address .col p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #252b42;
}
.container .contact-form {
  margin: 0 20px 80px;
  padding: 50px 80px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
}
.container .contact-form h3 {
  display: block;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #252b42;
}
.container .contact-form .message-info {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 20px;
  background: #2d65c0;
  border: 1px solid #2d65c0;
  border-radius: 10px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.container .contact-form .message-error {
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 20px;
  background: #e61c52;
  border: 1px solid #e61c52;
  border-radius: 10px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.container .contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.container .contact-form form input {
  padding: 20px;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  height: 64px;
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  outline: none;
}
.container .contact-form form textarea {
  padding: 20px;
  margin-bottom: 30px;
  display: block;
  width: 100%;
  min-height: 100px;
  resize: vertical;
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  outline: none;
}
.container .contact-form form button {
  padding: 15px 20px;
  background: #2dc071;
  border: 1px solid #2dc071;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #ffffff;
}
.container .contact-form form button:hover {
  cursor: pointer;
  background: #32d57e;
  border: 1px solid #32d57e;
}

.footer {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px 50px;
}
.footer .footer-logo {
  margin-bottom: 20px;
}
.footer .footer-logo img {
  display: block;
  width: 120px;
  height: 113px;
}
.footer .footer-text {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #737373;
}

.slider-wrap {
  width: 100%;
  position: relative;
  padding-left: 10px;
}
.slider-wrap .slider {
  width: 100%;
  margin: 0 auto 40px;
  overflow: hidden;
  position: relative;
}
.slider-wrap .slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  gap: 10px;
}
.slider-wrap .slide {
  min-width: calc(25% - 10px);
  box-sizing: border-box;
}
.slider-wrap img {
  width: 100%;
  display: block;
  cursor: pointer;
  border-radius: 10px;
}
.slider-wrap .navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.slider-wrap .next,
.slider-wrap .prev {
  position: absolute;
  border: none;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.15s ease;
  transition-property: width, height;
  cursor: pointer;
}
.slider-wrap .next:hover,
.slider-wrap .prev:hover {
  width: 42px;
  height: 42px;
}
.slider-wrap .prev {
  left: 0px;
  transform: translateY(-80%) translateX(-50%);
}
.slider-wrap .next {
  right: 20px;
  transform: translateY(-80%) translateX(50%);
}
.slider-wrap .last-visible {
  margin-right: 0 !important;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.overlay img {
  width: auto;
  height: 90%;
}

.overlay .close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 48px;
  color: #fff;
  background-color: transparent;
  height: 48px;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.open {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 50px !important;
}

.open-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #01983b;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.26);
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.open-btn:hover {
  background-color: #02b948;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.26);
}

/* Responsive styles */
@media (max-width: 960px) {
  .hero .main-image {
    padding-bottom: 40px;
  }
  .hero .main-image img {
    display: block;
    width: 576px;
    margin: 0 auto;
  }
  .container.main {
    margin: -250px auto 0;
  }
}
@media (max-width: 700px) {
  .hero {
    margin-bottom: 50px;
  }
  .hero.main {
    background-size: cover;
    background-position: center -90px;
    background-repeat: no-repeat;
    margin-bottom: 0px;
    padding: 0px;
    height: 1480px;
  }
  .hero .header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 30px 10px;
  }
  .hero .title-page {
    padding: 0 50px;
  }
  .hero .title-page h1 {
    padding-bottom: 50px;
    font-size: 30px;
    line-height: 34px;
  }
  .hero .menu ul {
    margin-top: 20px;
    display: block;
    text-align: center;
  }
  .hero .menu li {
    margin-left: 0px;
  }
  .hero .main-image {
    margin-bottom: 40px;
  }
  .hero .main-image img {
    width: 320px;
  }
  .hero .title h1 {
    font-size: 38px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.2px;
  }
  .hero .subtitle {
    margin-bottom: 50px;
    padding: 0 50px;
  }
  .hero .subtitle h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .container.main {
    margin: -465px auto 0;
  }
  .container .title-content {
    margin-bottom: 30px;
  }
  .container .title-content h3 {
    font-size: 22px;
    line-height: 26px;
  }
  .container .block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .container .block.revers {
    flex-direction: column-reverse;
  }
  .container .block img {
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 30px;
  }
  .container .content h3 {
    text-align: center;
  }
  .container .content p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin: 0px 30px 40px;
  }
  .container .content-address {
    margin: 0 20px 40px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container .content-address .col {
    margin-bottom: 30px;
  }
  .container .content-address .col img {
    margin-bottom: 15px;
  }
  .container .contact-form {
    margin: 0 20px 40px;
    padding: 30px 30px;
  }
}