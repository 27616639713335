* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-width: 330px;
    font-family: 'Open Sans', sans-serif;
}

a {
    color: #2091f9;
}

/* Header styles */
.hero {
    padding: 0px 20px;
    margin-bottom: 75px;
    width: 100%;
    // min-height: 500px;
    background: linear-gradient(39.97deg, #00f3b9 0.79%, #2187ff 79.29%);

    &.main {
        background: url('../images/template/main_page.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 0px;
        padding: 0px;
        height: 1380px;
    }

    .header {
        max-width: 1024px;
        margin: auto;
        padding: 50px 30px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            img {
                width: 120px;
                height: 113px;
            }
        }
    }

    .main-image {
        padding-bottom: 40px;
        img {
            display: block;
            width: 776px;
            margin: 0 auto;
        }
    }

    /* Menu styles */
    .menu {
        display: flex;
        align-items: center;

        ul {
            list-style: none;
            display: flex;
        }

        li {
            margin-left: 30px;
        }
        a {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #ffffff;
            text-decoration: none;

            &.active {
                color: #8ec2f2;
            }
        }
    }

    .title {
        margin-top: 40px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 50px;
        h1 {
            color: #ffffff;
            font-style: normal;
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            font-size: 58px;
            line-height: 70px;
            text-align: center;
            letter-spacing: 0.2px;
        }
    }

    .title-page {
        max-width: 768px;
        margin: auto;
        padding: 0 50px;
        h1 {
            padding-bottom: 60px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 54px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #ffffff;
        }
    }

    .subtitle {
        margin-bottom: 75px;
        margin-left: 30px;
        margin-right: 30px;
        h2 {
            color: #ffffff;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            letter-spacing: 0.2px;
        }
    }
}

.container {
    max-width: 768px;
    margin: 0 auto;
    font-size: 24px;

    &.main {
        margin: -250px auto 0;
    }

    .title-content {
        margin-bottom: 70px;
        h3 {
            padding: 0 30px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            letter-spacing: 0.2px;
            color: #e77c40;
        }
    }

    .block {
        margin-bottom: 70px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
            display: block;
            max-width: 400px;
            max-height: 400px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 34px;
            letter-spacing: 0.2px;
            color: #2091f9;
            margin: 50px 30px 30px 30px;
            text-transform: uppercase;
        }

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.2px;
            color: #2091f9;
            margin: 0 30px 30px 30px;
        }

        h4 {
            display: flex;
            align-items: center;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #2091f9;
            margin: 0 30px 30px 30px;

            img {
                margin-right: 5px;
                display: inline-block;
            }
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.2px;
            margin: 0 30px 30px 30px;
        }

        ul {
            display: block;
            margin-left: 50px;

            li {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.2px;

                a {
                    color: #2091f9;
                }
            }

            li::marker {
                color: #00f3b9;
                font-size: 14px;
            }
        }

        .apk {
            display: block;
            margin: 0 30px 400px 30px;
        }
    }

    .map {
        margin: 0 30px 80px;
        img {
            max-width: 768px;
            width: 100%;
            display: block;
        }
    }

    .content-address {
        margin: 0 20px 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            img {
                margin-bottom: 30px;
                display: block;
                width: 65px;
                height: 65px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.2px;
                color: #252b42;
            }
        }
    }

    .contact-form {
        margin: 0 20px 80px;
        padding: 50px 80px;

        background: #ffffff;
        border: 1px solid #dddddd;
        box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
        border-radius: 20px;

        h3 {
            display: block;
            margin-bottom: 40px;
            text-align: center;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.1px;
            color: #252b42;
        }

        .message-info {
            width: 100%;
            padding: 15px 20px;
            margin-bottom: 20px;
            background: #2d65c0;
            border: 1px solid #2d65c0;
            border-radius: 10px;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.2px;
            color: #ffffff;
        }

        .message-error {
            width: 100%;
            padding: 15px 20px;
            margin-bottom: 20px;
            background: #e61c52;
            border: 1px solid #e61c52;
            border-radius: 10px;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.2px;
            color: #ffffff;
        }

        form {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;

            input {
                padding: 20px;
                margin-bottom: 20px;
                display: block;
                width: 100%;
                height: 64px;
                background: #f5f5f5;
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.2px;
                outline: none;
            }

            textarea {
                padding: 20px;
                margin-bottom: 30px;
                display: block;
                width: 100%;
                min-height: 100px;
                resize: vertical;
                background: #f5f5f5;
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.2px;
                outline: none;
            }

            button {
                padding: 15px 20px;
                background: #2dc071;
                border: 1px solid #2dc071;
                border-radius: 10px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.2px;
                color: #ffffff;

                &:hover {
                    cursor: pointer;
                    background: #32d57e;
                    border: 1px solid #32d57e;
                }
            }
        }
    }
}

.footer {
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 30px 50px;

    .footer-logo {
        margin-bottom: 20px;
        img {
            display: block;
            width: 120px;
            height: 113px;
        }
    }

    .footer-text {
        text-align: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
        color: #737373;
    }
}

.slider-wrap {
    width: 100%;
    position: relative;
    padding-left: 10px;

    .slider {
        width: 100%;
        margin: 0 auto 40px;
        overflow: hidden;
        position: relative;
    }

    .slides {
        display: flex;
        transition: transform 0.5s ease-in-out;
        gap: 10px;
    }

    .slide {
        min-width: calc(25% - 10px);
        box-sizing: border-box;
    }

    img {
        width: 100%;
        display: block;
        cursor: pointer;
        border-radius: 10px;
    }

    .navigation {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .next,
    .prev {
        position: absolute;
        border: none;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
        background: #fff;
        transition: all 0.15s ease;
        transition-property: width, height;
        cursor: pointer;

        &:hover {
            width: 42px;
            height: 42px;
        }
    }

    .prev {
        left: 0px;
        transform: translateY(-80%) translateX(-50%);
    }

    .next {
        right: 20px;
        transform: translateY(-80%) translateX(50%);
    }

    .last-visible {
        margin-right: 0 !important;
    }
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.overlay img {
    width: auto;
    height: 90%;
}

.overlay .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 48px;
    color: #fff;
    background-color: transparent;
    height: 48px;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.open {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 50px !important;
}

.open-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #01983b;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.26);
    text-decoration: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.open-btn:hover {
    background-color: #02b948;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.26);
}

/* Responsive styles */
@media (max-width: 960px) {
    .hero {
        .main-image {
            padding-bottom: 40px;
            img {
                display: block;
                width: 576px;
                margin: 0 auto;
            }
        }
    }

    .container {
        &.main {
            margin: -250px auto 0;
        }
    }
}

@media (max-width: 700px) {
    .hero {
        margin-bottom: 50px;

        &.main {
            background-size: cover;
            background-position: center -90px;
            background-repeat: no-repeat;
            margin-bottom: 0px;
            padding: 0px;
            height: 1480px;
        }

        .header {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            padding: 50px 30px 10px;
        }

        .title-page {
            padding: 0 50px;
            h1 {
                padding-bottom: 50px;
                font-size: 30px;
                line-height: 34px;
            }
        }

        .menu ul {
            margin-top: 20px;
            display: block;
            text-align: center;
        }

        .menu li {
            margin-left: 0px;
        }

        .main-image {
            margin-bottom: 40px;
            img {
                width: 320px;
            }
        }

        .title {
            h1 {
                font-size: 38px;
                line-height: 42px;
                text-align: center;
                letter-spacing: 0.2px;
            }
        }

        .subtitle {
            margin-bottom: 50px;
            padding: 0 50px;
            h2 {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    .container {
        &.main {
            margin: -465px auto 0;
        }
    }

    .container {
        .title-content {
            margin-bottom: 30px;
            h3 {
                font-size: 22px;
                line-height: 26px;
            }
        }

        .block {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 0px;

            &.revers {
                flex-direction: column-reverse;
            }

            img {
                max-width: 300px;
                max-height: 300px;
                margin-bottom: 30px;
            }
        }

        .content {
            h3 {
                text-align: center;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.2px;
                margin: 0px 30px 40px;
            }
        }

        .content-address {
            margin: 0 20px 40px;
            flex-direction: column;
            justify-content: flex-start;

            .col {
                margin-bottom: 30px;

                img {
                    margin-bottom: 15px;
                }
            }
        }

        .contact-form {
            margin: 0 20px 40px;
            padding: 30px 30px;
        }
    }
}
